
  import { Component, Vue, Prop, Watch, Mixins } from "vue-property-decorator";
  import dialog from "@/vuex/dialog";
  import mixinPunch from "@/mixins/mixinPunch";
  import * as util from "@/util";
  import UtilPopup from "@/components/util/Popup.vue";

  @Component({
    components: {
      UtilPopup,
    },
  })
  export default class Dialog extends Mixins(mixinPunch) {
    get is_display() {
      return this.m.punch_complete_dialog !== null;
    }
    set is_display(val: boolean) {
      if (val == false) this.m.setPunchCompleteDialog(null);
    }

    get punch_type_name() {
      if (this.m.punch_complete_dialog == 0) {
        return util.$t("punch_in");
      } else if (this.m.punch_complete_dialog == 1) {
        return util.$t("punch_out");
      } else if (this.m.punch_complete_dialog == 2) {
        return util.$t("break_start");
      } else if (this.m.punch_complete_dialog == 3) {
        return util.$t("break_end");
      }
    }
  }
