
import { Component, Vue, Prop, Watch, Mixins } from 'vue-property-decorator';
import * as util from '@/util';
import mixinPunch from '@/mixins/mixinPunch'
import dialog from "@/vuex/dialog";
import PunchMain from "@/components/punch/Main.vue";

@Component({
  components: {
    PunchMain,
  }
})
export default class punch extends Mixins(mixinPunch) {
}
