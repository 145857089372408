import {
  Mutation,
  MutationAction,
  Action,
  VuexModule,
  getModule,
  Module,
} from "vuex-module-decorators";
import store from "@/store"; // デコレータでstoreを指定するためimportする必要あり
import * as util from "@/util";
import validate from "@/validations/validate";
import dialog from "@/vuex/dialog";
import Vue from "vue";
import global from "./global";
import manager from "./manager";
import Punch from "@/typesold/company";
import session from "../session";

@Module({ dynamic: true, store, name: "punch_manager", namespaced: true })
class Punch_manager extends VuexModule {
  mail?: string = null;
  pass?: string = null;

  branch_id: number = null;
  submitting: boolean = false;

  failure: boolean = false;
  punch_msg: string = "";

  punch_dialog?: number = null;
  punch_force_dialog: boolean = false;
  punch_complete_dialog?: number = null;

  failure_dialog: boolean = false;
  punch_dialog_msg: string = "";
  punch_force_dialog_msg: string = "";
  punch_time: string = "";

  employee_id?: number = null; //シフト外出勤の時に格納

  @Mutation
  public setMail(val?: string) {
    this.mail = val;
  }
  @Mutation
  public setPass(val?: string) {
    this.pass = val;
  }

  @Mutation
  public setBranchId(val?: number) {
    this.branch_id = val;
  }

  @Mutation
  public setFailure(val: boolean) {
    this.failure = val;
  }
  @Mutation
  public setPunchMsg(val: string) {
    this.punch_msg = val;
  }
  @Mutation
  public setSubmitting(val: boolean) {
    this.submitting = val;
  }

  @Mutation
  public setPunchDialog(val?: number) {
    //val = {0: 出勤, 1: 退勤, 2: 休憩開始, 3: 休憩終了}
    if (val === null) {
      this.mail = null;
      this.pass = null;
    }
    this.punch_dialog = val;
  }
  @Mutation
  public setPunchForceDialog(val: boolean) {
    this.punch_force_dialog = val;
  }
  @Mutation
  public setPunchCompleteDialog(val: number) {
    //val = {0: 出勤, 1: 退勤, 2: 休憩開始, 3: 休憩終了}
    this.punch_complete_dialog = val;
  }

  @Mutation
  public setFailureDialog(val: boolean) {
    this.failure_dialog = val;
  }
  @Mutation
  public setPunchDialogMsg(val: string) {
    this.punch_dialog_msg = val;
  }
  @Mutation
  public setPunchForceDialogMsg(val: string) {
    this.punch_force_dialog_msg = val;
  }
  @Mutation
  public setPunchTime(val: string) {
    this.punch_time = val;
  }
  @Mutation
  public setEmployeeId(val: number) {
    this.employee_id = val;
  }

  @Action({ rawError: true })
  public cancelAll() {}

  @Action({ rawError: true })
  public async punch_in(punch_type: number) {
    if (this.submitting) return;
    this.setSubmitting(true);
    this.reset_var();
    if (punch_type == 0) {
      //ICカード出勤
      // var idm = this.read_idm();
    } else if (punch_type == 1) {
      this.setPunchDialogMsg(util.$t("error.punch_enter_mail_pass"));
      this.setPunchDialog(0);
      this.setSubmitting(false);
    } else if (punch_type == 2) {
      await this.get_gps(0);
    } else if (punch_type == 3) {
      var cookie = util.getLocalStorage("mypc");
      if (cookie === null) cookie = "invalid";
      await this.punch_in_process({
        punch_type: 3,
        cookie,
        employee_id: Number(util.getLocalStorage("employee_id")),
        is_need_logined: true,
      });
      this.setSubmitting(false);
    }
  }

  @Action({ rawError: true })
  public async punch_in_process({
    punch_type,
    mail,
    pass,
    ido,
    keido,
    cookie,
    employee_id,
    is_need_logined,
  }: {
    punch_type: number;
    mail?: string;
    pass?: string;
    ido?: string;
    keido?: string;
    cookie?: string;
    employee_id?: number;
    is_need_logined: boolean;
  }) {
    var branch_id = is_need_logined ? this.branch_id : undefined;

    var data = await util.post(
      "punch/punch",
      { punch_type: 0, mail, pass, ido, keido, cookie, employee_id, branch_id },
      is_need_logined,
    );

    this.punch_in_after({ punch_type, data });
  }

  @Action({ rawError: true })
  public reset_var() {
    this.setPunchMsg(util.$t("punch_attention"));
    this.setFailure(false);
    this.setFailureDialog(false);
    this.setPunchTime(null);
  }

  @Action({ rawError: true })
  public punch_in_after({ punch_type, data }) {
    this.reset_var();
    if (data.code == 0) {
      //シフト外出勤
      this.setEmployeeId(data.employee_id);
      this.setPunchForceDialogMsg(
        util.$t("punch_confirm_out_punch_in", { name: data.employee_name }),
      );
      this.setPunchForceDialog(true);
    } else if (data.code == 2) {
      //出勤完了
      this.setPunchDialog(null);
      this.setPunchTime(data.punch_time);
      this.setPunchDialogMsg(util.$t("punch_finish_punch_in", { name: data.employee_name }));
      this.setPunchCompleteDialog(0);
    } else if (data.code == 11) {
      //IDかパスワード違う
      if (punch_type == 2) {
        this.setFailure(true);
        this.setPunchMsg(util.$t("error.punch_wrong_mail_code"));
      } else if (punch_type == 3) {
        this.setFailure(true);
        this.setPunchMsg(util.$t("error.punch_noregist_mypc"));
      } else {
        this.setFailureDialog(true);
        this.setPunchDialogMsg(util.$t("error.punch_wrong_mail_code"));
      }
    } else if (data.code == 12) {
      //すでに出勤中
      if (punch_type == 2 || punch_type == 3) {
        this.setFailure(true);
        this.setPunchMsg(util.$t("error.punch_already_punch_in", { name: data.employee_name }));
      } else {
        this.setFailureDialog(true);
        this.setPunchDialogMsg(
          util.$t("error.punch_already_punch_in", { name: data.employee_name }),
        );
      }
    }
  }

  @Action({ rawError: true })
  public async punch_out(punch_type: number) {
    if (this.submitting) return;
    this.setSubmitting(true);
    this.reset_var();
    if (punch_type == 0) {
      //ICカード退勤
    } else if (punch_type == 1) {
      this.setPunchDialogMsg(util.$t("error.punch_enter_mail_pass"));
      this.setPunchDialog(1);
      this.setSubmitting(false);
    } else if (punch_type == 2) {
      await this.get_gps(1);
    } else if (punch_type == 3) {
      var cookie = util.getLocalStorage("mypc");
      if (cookie === null) cookie = "invalid";
      await this.punch_out_process({
        punch_type: 3,
        cookie,
        employee_id: Number(util.getLocalStorage("employee_id")),
        is_need_logined: true,
      });
      this.setSubmitting(false);
    }
  }

  @Action({ rawError: true })
  public async punch_out_process({
    punch_type,
    mail,
    pass,
    ido,
    keido,
    cookie,
    employee_id,
    is_need_logined,
  }: {
    punch_type: number;
    mail?: string;
    pass?: string;
    ido?: string;
    keido?: string;
    cookie?: string;
    employee_id?: number;
    is_need_logined: boolean;
  }) {
    var branch_id = is_need_logined ? this.branch_id : undefined;

    var data = await util.post(
      "punch/punch",
      { punch_type: 1, mail, pass, ido, keido, cookie, employee_id, branch_id },
      is_need_logined,
    );

    this.punch_out_after({ punch_type, data });
  }

  @Action({ rawError: true })
  public punch_out_after({ punch_type, data }) {
    this.reset_var();
    if (data.code == 3) {
      //退勤完了
      this.setPunchDialog(null);
      this.setPunchTime(data.punch_time);
      this.setPunchDialogMsg(util.$t("punch_finish_punch_out", { name: data.employee_name }));
      this.setPunchCompleteDialog(1);
    } else if (data.code == 11) {
      //IDかパスワード違う
      if (punch_type == 2) {
        this.setFailure(true);
        this.setPunchMsg(util.$t("error.punch_wrong_mail_code"));
      } else if (punch_type == 3) {
        this.setFailure(true);
        this.setPunchMsg(util.$t("error.punch_noregist_mypc"));
      } else {
        this.setFailureDialog(true);
        this.setPunchDialogMsg(util.$t("error.punch_wrong_mail_code"));
      }
    } else if (data.code == 13) {
      //出勤中でない
      if (punch_type == 2 || punch_type == 3) {
        this.setFailure(true);
        this.setPunchMsg(util.$t("error.punch_no_punch_in", { name: data.employee_name }));
      } else {
        this.setFailureDialog(true);
        this.setPunchDialogMsg(util.$t("error.punch_no_punch_in", { name: data.employee_name }));
      }
    } else if (data.code == 16) {
      //休憩中
      if (punch_type == 2 || punch_type == 3) {
        this.setFailure(true);
        this.setPunchMsg(
          util.$t("error.cannot_punch_out_during_break", { name: data.employee_name }),
        );
      } else {
        this.setFailureDialog(true);
        this.setPunchDialogMsg(
          util.$t("error.cannot_punch_out_during_break", { name: data.employee_name }),
        );
      }
    }
  }

  @Action({ rawError: true })
  public async break_in(punch_type: number) {
    if (this.submitting) return;
    this.setSubmitting(true);
    this.reset_var();
    if (punch_type == 0) {
      //ICカード出勤
      // var idm = this.read_idm();
    } else if (punch_type == 1) {
      this.setPunchDialogMsg(util.$t("error.punch_enter_mail_pass"));
      this.setPunchDialog(2);
      this.setSubmitting(false);
    } else if (punch_type == 2) {
      await this.get_gps(2);
    } else if (punch_type == 3) {
      var cookie = util.getLocalStorage("mypc");
      if (cookie === null) cookie = "invalid";
      await this.break_in_process({
        punch_type: 3,
        cookie,
        employee_id: Number(util.getLocalStorage("employee_id")),
        is_need_logined: true,
      });
      this.setSubmitting(false);
    }
  }

  @Action({ rawError: true })
  public async break_in_process({
    punch_type,
    mail,
    pass,
    ido,
    keido,
    cookie,
    employee_id,
    is_need_logined,
  }: {
    punch_type: number;
    mail?: string;
    pass?: string;
    ido?: string;
    keido?: string;
    cookie?: string;
    employee_id?: number;
    is_need_logined: boolean;
  }) {
    var branch_id = is_need_logined ? this.branch_id : undefined;

    var data = await util.post(
      "punch/punch",
      { punch_type: 2, mail, pass, ido, keido, cookie, employee_id, branch_id },
      is_need_logined,
    );

    this.break_in_after({ punch_type, data });
  }

  @Action({ rawError: true })
  break_in_after({ punch_type, data }) {
    this.reset_var();
    if (data.code == 4) {
      //休憩開始完了
      this.setPunchDialog(null);
      this.setPunchTime(data.punch_time);
      this.setPunchDialogMsg(util.$t("punch_finish_break_start", { name: data.employee_name }));
      this.setPunchCompleteDialog(2);
    } else if (data.code == 11) {
      //IDかパスワード違う
      if (punch_type == 2) {
        this.setFailure(true);
        this.setPunchMsg(util.$t("error.punch_wrong_mail_code"));
      } else if (punch_type == 3) {
        this.setFailure(true);
        this.setPunchMsg(util.$t("error. punch_noregist_mypc"));
      } else {
        this.setFailureDialog(true);
        this.setPunchDialogMsg(util.$t("error.punch_wrong_mail_code"));
      }
    } else if (data.code == 13) {
      //出勤されていない
      if (punch_type == 2 || punch_type == 3) {
        this.setFailure(true);
        this.setPunchMsg(util.$t("error.punch_no_punch_in", { name: data.employee_name }));
      } else {
        this.setFailureDialog(true);
        this.setPunchDialogMsg(util.$t("error.punch_no_punch_in", { name: data.employee_name }));
      }
    } else if (data.code == 14) {
      //すでに休憩中
      if (punch_type == 2 || punch_type == 3) {
        this.setFailure(true);
        this.setPunchMsg(util.$t("error.punch_already_break_start", { name: data.employee_name }));
      } else {
        this.setFailureDialog(true);
        this.setPunchDialogMsg(
          util.$t("error.punch_already_break_start", { name: data.employee_name }),
        );
      }
    }
  }

  @Action({ rawError: true })
  public async break_out(punch_type: number) {
    if (this.submitting) return;
    this.setSubmitting(true);
    this.reset_var();
    if (punch_type == 0) {
      //ICカード出勤
      // var idm = this.read_idm();
    } else if (punch_type == 1) {
      this.setPunchDialogMsg(util.$t("error.punch_enter_mail_pass"));
      this.setPunchDialog(3);
      this.setSubmitting(false);
    } else if (punch_type == 2) {
      await this.get_gps(3);
    } else if (punch_type == 3) {
      var cookie = util.getLocalStorage("mypc");
      if (cookie === null) cookie = "invalid";
      await this.break_out_process({
        punch_type: 3,
        cookie,
        employee_id: Number(util.getLocalStorage("employee_id")),
        is_need_logined: true,
      });
      this.setSubmitting(false);
    }
  }

  @Action({ rawError: true })
  public async break_out_process({
    punch_type,
    mail,
    pass,
    ido,
    keido,
    cookie,
    employee_id,
    is_need_logined,
  }: {
    punch_type: number;
    mail?: string;
    pass?: string;
    ido?: string;
    keido?: string;
    cookie?: string;
    employee_id?: number;
    is_need_logined: boolean;
  }) {
    var branch_id = is_need_logined ? this.branch_id : undefined;

    var data = await util.post(
      "punch/punch",
      { punch_type: 3, mail, pass, ido, keido, cookie, employee_id, branch_id },
      is_need_logined,
    );

    this.break_out_after({ punch_type, data });
  }

  @Action({ rawError: true })
  break_out_after({ punch_type, data }) {
    this.reset_var();
    if (data.code == 5) {
      //休憩終了完了
      this.setPunchDialog(null);
      this.setPunchTime(data.punch_time);
      this.setPunchDialogMsg(util.$t("punch_finish_break_end", { name: data.employee_name }));
      this.setPunchCompleteDialog(3);
    } else if (data.code == 11) {
      //IDかパスワード違う
      if (punch_type == 2) {
        this.setFailure(true);
        this.setPunchMsg(util.$t("error.punch_wrong_mail_code"));
      } else if (punch_type == 3) {
        this.setFailure(true);
        this.setPunchMsg(util.$t("error. punch_noregist_mypc"));
      } else {
        this.setFailureDialog(true);
        this.setPunchDialogMsg(util.$t("error.punch_wrong_mail_code"));
      }
    } else if (data.code == 15) {
      //休憩中でない
      if (punch_type == 2 || punch_type == 3) {
        this.setFailure(true);
        this.setPunchMsg(util.$t("error.punch_no_break_start", { name: data.employee_name }));
      } else {
        this.setFailureDialog(true);
        this.setPunchDialogMsg(util.$t("error.punch_no_break_start", { name: data.employee_name }));
      }
    }
  }

  @Action({ rawError: true })
  public async punch_in_force(punch_type: number) {
    this.reset_var();
    if (punch_type == 0) {
      //IC
      await this.punch_in_force_process({
        punch_type: punch_type,
        employee_id: this.employee_id,
        is_need_logined: false,
      });
    } else if (punch_type == 1) {
      //メール
      await this.punch_in_force_process({
        punch_type: punch_type,
        employee_id: this.employee_id,
        is_need_logined: false,
      });
    } else if (punch_type == 2) {
      //GPS
      await this.get_gps(4);
    } else if (punch_type == 3) {
      //Mypc
      var cookie = util.getLocalStorage("mypc");
      if (cookie === null) cookie = "invalid";
      await this.punch_in_force_process({
        punch_type: 3,
        cookie,
        employee_id: Number(util.getLocalStorage("employee_id")),
        is_need_logined: true,
      });
    }
  }

  @Action({ rawError: true })
  public async punch_in_force_process({
    punch_type,
    mail,
    pass,
    ido,
    keido,
    cookie,
    employee_id,
    is_need_logined,
  }: {
    punch_type: number;
    mail?: string;
    pass?: string;
    ido?: string;
    keido?: string;
    cookie?: string;
    employee_id?: number;
    is_need_logined: boolean;
  }) {
    var branch_id = is_need_logined ? this.branch_id : undefined;

    var data = await util.post(
      "punch/punch",
      { punch_type: 4, mail, pass, ido, keido, cookie, employee_id, branch_id },
      is_need_logined,
    );

    this.punch_in_force_after({ punch_type, data });
  }

  @Action({ rawError: true })
  public punch_in_force_after({ punch_type, data }) {
    this.reset_var();
    if (data.code == 1) {
      //出勤完了
      this.setPunchDialog(null);
      this.setPunchForceDialog(null);
      this.setPunchTime(data.punch_time);
      this.setPunchDialogMsg(util.$t("punch_finish_out_punch_in", { name: data.employee_name }));
      this.setPunchCompleteDialog(0);
    } else if (data.code == 11) {
      //IDかパスワード違う
      if (punch_type == 2 || punch_type == 3) {
        this.setFailure(true);
        this.setPunchMsg(util.$t("error.punch_unknown_error"));
      } else {
        this.setFailureDialog(true);
        this.setPunchDialogMsg(util.$t("error.punch_unknown_error"));
      }
    }
  }

  @Action({ rawError: true })
  async get_gps(type) {
    //type: 0: 出勤, 1: 退勤, 2: 休憩開始, 3: 休憩終了
    this.setPunchMsg(util.$t("punch_getting_location"));
    if (typeof navigator.geolocation === "undefined") {
      this.setFailure(true);
      this.setPunchMsg(util.$t("error.punch_browser_not_supported_gps"));
      return false;
    }
    var wOptions = {
      enableHighAccuracy: true, // true : 高精度
      timeout: 10000, // タイムアウト : ミリ秒
      maximumAge: 0, // データをキャッシュ時間 : ミリ秒
    };
    if (type == 0) {
      navigator.geolocation.getCurrentPosition(this.gps_ok_punch_in, this.gps_ng, wOptions);
    } else if (type == 1) {
      navigator.geolocation.getCurrentPosition(this.gps_ok_punch_out, this.gps_ng, wOptions);
    } else if (type == 2) {
      navigator.geolocation.getCurrentPosition(this.gps_ok_break_in, this.gps_ng, wOptions);
    } else if (type == 3) {
      navigator.geolocation.getCurrentPosition(this.gps_ok_break_out, this.gps_ng, wOptions);
    } else if (type == 4) {
      navigator.geolocation.getCurrentPosition(this.gps_ok_punch_in_force, this.gps_ng, wOptions);
    }

    return true;
  }

  @Action({ rawError: true })
  async gps_ok_punch_in(argPos) {
    this.setPunchMsg(util.$t("punch_attention"));
    await this.punch_in_process({
      punch_type: 2,
      ido: argPos.coords.latitude,
      keido: argPos.coords.longitude,
      employee_id: Number(util.getLocalStorage("employee_id")),
      is_need_logined: true,
    });
    this.setSubmitting(false);
  }
  @Action({ rawError: true })
  async gps_ok_punch_out(argPos) {
    this.setPunchMsg(util.$t("punch_attention"));
    await this.punch_out_process({
      punch_type: 2,
      ido: argPos.coords.latitude,
      keido: argPos.coords.longitude,
      employee_id: Number(util.getLocalStorage("employee_id")),
      is_need_logined: true,
    });
    this.setSubmitting(false);
  }
  @Action({ rawError: true })
  async gps_ok_break_in(argPos) {
    this.setPunchMsg(util.$t("punch_attention"));
    await this.break_in_process({
      punch_type: 2,
      ido: argPos.coords.latitude,
      keido: argPos.coords.longitude,
      employee_id: Number(util.getLocalStorage("employee_id")),
      is_need_logined: true,
    });
    this.setSubmitting(false);
  }
  @Action({ rawError: true })
  async gps_ok_break_out(argPos) {
    this.setPunchMsg(util.$t("punch_attention"));
    await this.break_out_process({
      punch_type: 2,
      ido: argPos.coords.latitude,
      keido: argPos.coords.longitude,
      employee_id: Number(util.getLocalStorage("employee_id")),
      is_need_logined: true,
    });
    this.setSubmitting(false);
  }
  @Action({ rawError: true })
  async gps_ok_punch_in_force(argPos) {
    this.setPunchMsg(util.$t("punch_attention"));
    await this.punch_in_force_process({
      punch_type: 4,
      ido: argPos.coords.latitude,
      keido: argPos.coords.longitude,
      employee_id: Number(util.getLocalStorage("employee_id")),
      is_need_logined: true,
    });
    this.setSubmitting(false);
  }

  @Action({ rawError: true })
  async gps_ng(argErr) {
    this.setFailure(true);
    switch (argErr.code) {
      case 1:
        this.setPunchMsg(util.$t("error.punch_not_admitted_gps"));
        break;
      case 2:
        this.setPunchMsg(util.$t("error.punch_cannot_get_location"));
        break;
      case 3:
        this.setPunchMsg(util.$t("error.punch_timeout"));
        break;
    }
    this.setSubmitting(false);
  }
}

export default getModule(Punch_manager);
