
  import { Component, Vue, Prop, Watch, Mixins } from "vue-property-decorator";
  import * as util from "@/util";
  import mixinPunch from "@/mixins/mixinPunch";
  import dialog from "@/vuex/dialog";
  import login from "@/vuex/login";
  import { EmployeeHeaderData } from "@/typesold/header";
  import CommonProgress from "@/components/common/Progress.vue";
  import CommonSelect from "@/components/common/Select.vue";
  import CommonCheck from "@/components/common/Check.vue";
  import PunchDialogPunch from "@/components/punch/dialog/Punch.vue";
  import PunchDialogForce from "@/components/punch/dialog/Force.vue";
  import PunchDialogComplete from "@/components/punch/dialog/Complete.vue";

  @Component({
    components: {
      CommonProgress,
      CommonSelect,
      CommonCheck,
      PunchDialogPunch,
      PunchDialogForce,
      PunchDialogComplete,
    },
  })
  export default class Main extends Mixins(mixinPunch) {
    nowtime = "";

    created() {
      if (this.punch_type == 2 || this.punch_type == 3) {
        this.branch_id = Number(this.s_branch_id);
      }
      this.m.setPunchMsg(util.$t("punch_attention") as string);
      setInterval(() => this.set_time(), 1000);
    }

    get branch_id() {
      return this.m.branch_id;
    }
    set branch_id(value) {
      this.m.setBranchId(value);
    }

    set_time() {
      this.nowtime = new Date().toLocaleString();
    }

    async logout() {
      await login.punch_logout();
      this.$router.push({ name: "login", params: { company_code: this.s_company_code } });
    }

    get login_gps_punch() {
      const login_gps = Number(util.getLocalStorage("login_gps"));
      if (login_gps === undefined) {
        return false;
      } else {
        return Number(login_gps) == 1;
      }
    }
    change_login_gps_punch(val) {
      util.setLocalStorage("login_gps", val ? 1 : 0);
    }
  }
