import { Component, Vue, Mixins } from 'vue-property-decorator';
import global from "@/vuex/punch/global";
import * as util from '@/util';
import utilMixins from '@/mixins';
import manager from '@/vuex/punch/manager';

@Component
export default class mixinPunch extends Mixins(utilMixins) {
  get is_set_global(): boolean {
    return global.is_set;
  }

  get is_valid_global(): boolean {
    return global.is_valid;
  }

  async get_global() {
    await global.fetch();
  }

  get global() {
    if (!this.is_set_global || !this.is_valid_global) this.get_global();
    if (this.is_set_global) 
      return global.data;
  }
  
  get m() {
    return manager;
  }

  get punch_type() {//0: IC 1: Pass 2: GPS 3: MyPC
    return this.query.type;
  };
  get punch_name() {
    if (this.punch_type == 0) {
      return "ICカード";
    } else if (this.punch_type == 1) {
      return "ID/パスワード";
    } else if (this.punch_type == 2) {
      return "GPS";
    } else if (this.punch_type == 3) {
      return "MyPC";
    }
  };

  get branch_hash() {
    return this.global.branch_hash;
  }
  get branches() {
     return this.global.branches;
  }
  
}