
  import { Component, Mixins } from "vue-property-decorator";
  import mixinPunch from "@/mixins/mixinPunch";
  import * as util from "@/util";
  import UtilPopup from "@/components/util/Popup.vue";

  @Component({
    components: {
      UtilPopup,
    },
  })
  export default class Dialog extends Mixins(mixinPunch) {
    get mail() {
      return this.m.mail;
    }
    set mail(value) {
      this.m.setMail(value);
    }
    get pass() {
      return this.m.pass;
    }
    set pass(value) {
      this.m.setPass(value);
    }

    get is_display() {
      return this.m.punch_dialog !== null;
    }
    set is_display(val: boolean) {
      if (val == false) this.m.setPunchDialog(null);
    }

    async exec_punch() {
      if (this.m.punch_dialog == 0) {
        await this.m.punch_in_process({
          punch_type: this.punch_type,
          mail: this.mail,
          pass: this.pass,
          is_need_logined: false,
        });
      } else if (this.m.punch_dialog == 1) {
        await this.m.punch_out_process({
          punch_type: this.punch_type,
          mail: this.mail,
          pass: this.pass,
          is_need_logined: false,
        });
      } else if (this.m.punch_dialog == 2) {
        await this.m.break_in_process({
          punch_type: this.punch_type,
          mail: this.mail,
          pass: this.pass,
          is_need_logined: false,
        });
      } else if (this.m.punch_dialog == 3) {
        await this.m.break_out_process({
          punch_type: this.punch_type,
          mail: this.mail,
          pass: this.pass,
          is_need_logined: false,
        });
      }
    }

    get punch_type_name() {
      if (this.m.punch_dialog == 0) {
        return util.$t("punch_in");
      } else if (this.m.punch_dialog == 1) {
        return util.$t("punch_out");
      } else if (this.m.punch_dialog == 2) {
        return util.$t("break_start");
      } else if (this.m.punch_dialog == 3) {
        return util.$t("break_end");
      }
    }
  }
