
  import { Component, Vue, Prop, Watch, Mixins } from "vue-property-decorator";
  import dialog from "@/vuex/dialog";
  import mixinPunch from "@/mixins/mixinPunch";
  import * as util from "@/util";
  import UtilPopup from "@/components/util/Popup.vue";

  @Component({
    components: {
      UtilPopup,
    },
  })
  export default class Dialog extends Mixins(mixinPunch) {
    get is_display() {
      return this.m.punch_force_dialog;
    }
    set is_display(val: boolean) {
      this.m.setPunchForceDialog(val);
    }

    async exec_punch() {
      await this.m.punch_in_force(this.punch_type);
    }
  }
